import AddActionModal from 'app/components/cores/audit/add-action-modal';
import useToggle from 'app/hooks/use-toggle';
import clsx from 'clsx';

import { Button } from '@mui/material';

type CreateActionButtonProps = {
  listId: number;
  responseId: number;
  disabled: boolean;
  className?: string;
};

const CreateActionButton = ({ listId, responseId, className, disabled }: CreateActionButtonProps) => {
  const { state: isOpen, setActive: show, setInactive: hide } = useToggle(false);

  return (
    <>
      <Button
        className={clsx(
          'normal-case text-11 bg-paper text-secondaryMain font-400',
          disabled && 'cursor-not-allowed',
          className,
        )}
        disableElevation
        onClick={show}
        disabled={disabled}
      >
        Add Action
      </Button>

      {isOpen && (
        <AddActionModal
          isOpen={true}
          onClose={hide}
          listId={listId}
          responseId={responseId}
        />
      )}
    </>
  );
};

export default CreateActionButton;

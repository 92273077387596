import FilterContext, { TFilterContext } from 'app/components/cores/employee-calendar/filter-context';
import FlagButton from 'app/components/cores/tasks/components/flag-button';
import { useDateTimeTz } from 'app/hooks/use-date-time-tz';
import useOnClickOutside from 'app/hooks/use-on-click-outside';
import useTask from 'app/hooks/use-task';
import useTaskUrl from 'app/hooks/use-task-url';
import clsx from 'clsx';
import _isEmpty from 'lodash/isEmpty';
import { TResponse } from 'models';
import { TTaskStatus } from 'models/employee/task.model';
import { PropsWithChildren, useContext, useMemo, useRef, useState } from 'react';
import { getBrowserTimezone } from 'utils/date';

import { ErrorMessage } from '@hookform/error-message';
import CheckIcon from '@mui/icons-material/Check';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SendIcon from '@mui/icons-material/Send';
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import AttachmentViewer from './attachment-viewer';
import CreateActionButton from './create-action-button';

type Props = {
  name?: string;
  question?: any; // TODO update type
  preview?: boolean;
  task?: Omit<TResponse, 'item'>;
  rightActions?: React.ReactNode;
  isLoading?: boolean;
  actionLabel?: string;
  listStatus?: TTaskStatus;
  comment?: string;
  isShowComment?: boolean;
  responseId?: number;
  onCommentChange?: (nextComment: string) => void;
  onCommentSend?: () => void;
  onApplicableChange?: (notApplicable: boolean) => void;
  isAudit?: boolean;
  disabled?: boolean;
};

const menuItemStyle = {
  fontWeight: 600,
  fontSize: 11,
};

const QuestionLayout = ({
  question,
  children,
  preview,
  task,
  rightActions,
  isLoading,
  actionLabel,
  listStatus,
  comment: originalComment,
  name,
  isShowComment = true,
  responseId,
  onCommentChange,
  onCommentSend,
  onApplicableChange,
  isAudit,
  disabled,
}: PropsWithChildren<Props>) => {
  const { prompt, description, notApplicable: allowNA } = question;
  const { status, user, comment = '', updatedAt, location, flagged } = task || {};
  const context = useContext(FilterContext) as TFilterContext;
  const timezoneBySelector = context?.timezoneBySelector ?? getBrowserTimezone();
  const { getDateTz } = useDateTimeTz(timezoneBySelector);
  const updatedAtTz = getDateTz(updatedAt, 'MMM DD, YYYY @ hh:mmA');
  const { listId } = useTaskUrl();

  const statusName = useMemo(() => {
    if (!status) return '';

    const statusMap: Record<string, string> = {
      not_applicable: 'Not Applicable',
      in_progress: 'In Progress',
      completed: 'Completed',
      overdue: 'Late',
      incomplete: 'Incomplete',
    };

    return statusMap[status] || '';
  }, [status]);

  const isNotApplicable = status === 'not_applicable';
  const attachments = question?.attachments || [];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = !!anchorEl;
  const theme = useTheme();
  const classes = useStyles();
  const commentRef = useRef(null);
  const isFocusedComment = useRef(false);
  const { triggerSubmitTask } = useTask();

  const isCommentChanged = useMemo(() => {
    return (!comment && !!originalComment) || (!originalComment && !!comment) || comment !== originalComment;
  }, [comment, originalComment]);

  useOnClickOutside(commentRef, (event: any) => {
    if (!isFocusedComment.current || !isCommentChanged) return;
    onCommentSend?.();
    triggerSubmitTask(event?.target);
  });

  const commentIconColor = useMemo(() => {
    return listStatus === 'overdue' ? 'overdue.main' : theme.palette.primary.main;
  }, [listStatus, theme]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleChange = (event: any) => {
    if (preview) return;

    const nextComment = event.target.value;
    onCommentChange?.(nextComment);
  };

  const handleChangeApplicable = (notApplicable: boolean) => () => {
    if (preview) return;

    onApplicableChange?.(notApplicable);
    handleClose();
  };

  const handleFocusComment = () => {
    isFocusedComment.current = true;
  };

  const handleBlurComment = () => {
    isFocusedComment.current = false;
  };

  return (
    <div
      // @ts-ignore
      name={name}
      className="flex"
      data-responseid={responseId}
    >
      {!!name && (
        <ErrorMessage
          name={name}
          render={() => <div className="flex items-stretch w-8 bg-errorMain" />}
        />
      )}

      <div className="flex-1 px-16 pt-16 md:px-32 md:pt-24">
        <div className="pt-16 pb-8 space-y-8 last:border-b-0 first:pt-0">
          {/* Start of prompt */}
          <div className="flex gap-8 justify-between items-start">
            {prompt && <Typography className="flex-1 font-bold text-13">{prompt}</Typography>}
            {/* flag item */}
            {isAudit && (
              <FlagButton
                active={!!flagged}
                responseId={task?.id!}
                disabled={!!disabled}
              />
            )}
          </div>
          {/* End of prompt */}

          {/* Start of description */}
          {description && <Typography className="text-11">{description}</Typography>}
          {/* End of description */}

          {/* attachments */}
          {attachments && attachments?.length > 0 && <AttachmentViewer attachments={attachments} />}
          {/* end of attachments */}

          {/* start of answer helper text */}
          <div className="flex justify-between items-center">
            {isNotApplicable ? (
              <div />
            ) : (
              <Typography className="text-secondaryLight text-11 font-400">
                {actionLabel || 'Your Answer/Action'} {question.required && '*'}
              </Typography>
            )}

            <div className="flex gap-8 items-center">
              {rightActions}
              {isAudit && (
                <CreateActionButton
                  listId={Number(listId)}
                  responseId={Number(task?.id)}
                  disabled={!!disabled}
                />
              )}
              {allowNA && (
                <div>
                  <IconButton
                    disabled={preview}
                    onClick={handleClick}
                  >
                    <MoreVertIcon className="text-20" />
                  </IconButton>
                  <Menu
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                  >
                    <MenuItem
                      sx={menuItemStyle}
                      onClick={handleChangeApplicable(false)}
                    >
                      <div className="flex justify-center items-center w-24 h-24">
                        {status !== 'not_applicable' && (
                          <CheckIcon
                            color="primary"
                            className="text-16"
                          />
                        )}
                      </div>
                      <Typography className="text-11 font-600">Applicable</Typography>
                    </MenuItem>
                    <MenuItem
                      sx={menuItemStyle}
                      onClick={handleChangeApplicable(true)}
                    >
                      <div className="flex justify-center items-center w-24 h-24">
                        {status === 'not_applicable' && (
                          <CheckIcon
                            color="primary"
                            className="text-16"
                          />
                        )}
                      </div>
                      <Typography className="text-11 font-600">Not Applicable</Typography>
                    </MenuItem>
                  </Menu>
                </div>
              )}
            </div>
          </div>
          {/* end of answer helper text */}

          {/* Start of error message */}
          {!!name && (
            <ErrorMessage
              name={name}
              render={({ message }) => <Typography className="text-errorMain text-11">{message}</Typography>}
            />
          )}
          {/* End of error message */}

          {/* Start of answer */}
          {!isNotApplicable && <div>{children}</div>}
          {/* End of answer */}

          {/* Start of Status */}
          <div>
            <Box sx={{ mt: '8px' }}>
              {status && (
                <Typography
                  className="mr-4 font-bold capitalize text-secondaryLight text-11"
                  component="span"
                >
                  {statusName}
                </Typography>
              )}
              {!_isEmpty(user) && (
                <>
                  <Typography
                    className="font-bold text-secondaryLight text-11"
                    component="span"
                  >
                    {status && user && '|'} {user?.fullName} :
                  </Typography>
                  <Typography
                    className="text-secondaryLight text-11"
                    component="span"
                  >
                    {!!location && ` ${location},`} {updatedAt && updatedAtTz}
                  </Typography>
                </>
              )}
            </Box>
          </div>

          {/* End of status */}
          <div>
            <Divider
              sx={{
                marginTop: '16px !important',
                borderStyle: 'dashed',
              }}
            />
          </div>

          {/* Start of Comment */}
          {isShowComment && (
            <div ref={commentRef}>
              <div className="flex relative justify-between">
                <div className="absolute z-10 pl-12 mt-12">
                  <CommentOutlinedIcon className="text-18 text-secondaryLight" />
                </div>
                <div className="relative flex-1">
                  <TextField
                    multiline
                    variant="outlined"
                    placeholder="(Optional) Add a comment ..."
                    fullWidth
                    className="bg-gray-20"
                    InputProps={{
                      classes: {
                        root: 'pt-12',
                        notchedOutline: classes.comment,
                        focused: clsx(listStatus === 'overdue' && classes.commentFocused),
                      },
                    }}
                    sx={{
                      textarea: {
                        fontSize: '11px',
                        paddingLeft: 4,
                      },
                      '& .Mui-disabled': {
                        backgroundColor: 'white !important',
                        WebkitTextFillColor: '#7C808B !important',
                      },
                      '& .MuiFilledInput-root': {
                        padding: '4px !important',

                        '&:before, &:after': {
                          display: 'none',
                        },
                      },
                    }}
                    disabled={preview}
                    value={comment || ''}
                    onChange={handleChange}
                    onFocus={handleFocusComment}
                    onBlur={handleBlurComment}
                  />
                  {!preview && isCommentChanged && (
                    <IconButton
                      className="absolute right-0 bottom-0 mb-8"
                      disabled={preview}
                      sx={{ color: commentIconColor }}
                      onClick={onCommentSend}
                    >
                      <SendIcon
                        className="text-16"
                        color="inherit"
                      />
                    </IconButton>
                  )}
                </div>
              </div>
            </div>
          )}

          {/* End of Comment */}
        </div>
      </div>

      {isLoading && (
        <div className="flex absolute inset-0 justify-center items-center mt-0 bg-white bg-opacity-25 z-99">
          <CircularProgress
            size={24}
            sx={{
              color: listStatus === 'overdue' ? 'overdue.main' : 'primary.main',
            }}
          />
        </div>
      )}
    </div>
  );
};

export default QuestionLayout;

const useStyles = makeStyles((theme: any) => ({
  comment: {
    borderRadius: 0,
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
  },
  commentFocused: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.overdue.main} !important`,
    },
  },
}));

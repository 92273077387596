import AddActionForm from 'app/components/cores/audit/add-action-form';
import { useAddEmployeeAction } from 'app/hooks/api/employee/tasks';
import useShowMessage from 'app/hooks/use-show-message';
import { EmployeeAction, EmployeeAddActionFormValues } from 'models/employee/action.model';
import moment from 'moment';
import { FormProvider, useForm } from 'react-hook-form';
import { formatDate } from 'utils/date';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';

const schema = yup.object({
  description: yup.string().required('Description is required'),
  important: yup.boolean().optional().default(false),
  users: yup.object({}).optional().nullable(),
  orgRoles: yup.object({}),
  startDate: yup
    .string()
    .required('Start date is required')
    .test('is-valid', 'Start date is invalid', (value: unknown) => {
      const currentDate = new Date(value as Date);

      // if dont have value, dont check
      if (!value) return true;

      return (
        !Number.isNaN(currentDate.getTime()) && moment(currentDate.toISOString()).isSameOrAfter(moment().startOf('day'))
      );
    }),
  startTime: yup.string().required('Start time is required'),
  endDate: yup.string().optional(),
  endTime: yup.string().test('is-valid', 'End time is invalid', (value: unknown, context: any) => {
    const currentDate = new Date();
    const convertedTime = moment(value as string, 'h:mm A').format('HH:mm');
    const [hours, minutes] = convertedTime.split(':');
    currentDate.setHours(Number(hours), Number(minutes), 0, 0);
    const parentValue = context.parent;
    const { endDate } = parentValue;
    if (endDate) return !Number.isNaN(currentDate.getTime());
    return true;
  }),
});

type Props = {
  isOpen: boolean;
  onClose: () => void;
  listId: number;
  responseId: number;
};

const AddActionModal = ({ isOpen, onClose, listId, responseId }: Props) => {
  const { showSuccess, showError } = useShowMessage();
  const methods = useForm<EmployeeAddActionFormValues>({
    defaultValues: {
      description: '',
      important: false,
      users: {
        userIds: [],
        users: [],
      },
      orgRoles: {
        ids: [],
        names: [],
      },
      startDate: new Date().toISOString(),
      // start time should be 00:00 of the day
      startTime: moment(new Date().setHours(0, 0, 0, 0)).format('h:mm A'),
      endDate: undefined,
      endTime: undefined,
    },
    resolver: yupResolver(schema),
  });
  const { handleSubmit, reset } = methods;

  const { mutate: addEmployeeAction, isLoading: isAddingAction } = useAddEmployeeAction(listId, responseId);

  const handleCreateAction = (data: EmployeeAddActionFormValues) => {
    // convert to 24 hour format
    const startTime = moment(data.startTime, 'h:mm A').format('HH:mm');
    const endTime = data.endTime ? moment(data.endTime, 'h:mm A').format('HH:mm') : undefined;

    const body: EmployeeAction = {
      description: data.description,
      important: data.important,
      orgRoleIds: data.orgRoles?.ids?.length > 0 ? data.orgRoles.ids : undefined,
      userIds: data.users?.userIds?.length > 0 ? data.users.userIds : undefined,
      displayAt: `${formatDate(data.startDate, 'YYYY/MM/DD')} ${startTime}`,
      expireAt: data.endDate && endTime ? `${formatDate(data.endDate, 'YYYY/MM/DD')} ${endTime}` : undefined,
    };

    addEmployeeAction(
      { auditAction: body },
      {
        onSuccess: () => {
          onClose();
          reset();
          showSuccess('Action created successfully');
        },
        onError: () => {
          showError('Could not create action');
        },
      },
    );
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={isOpen}
      classes={{
        paper: 'rounded-16',
      }}
    >
      <DialogTitle
        component="div"
        className="space-y-8 uppercase bg-white"
      >
        Add Action
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{ right: 8, top: 8 }}
        className="absolute text-16"
      >
        <CloseIcon className="text-black text-20" />
      </IconButton>
      <form onSubmit={handleSubmit(handleCreateAction)}>
        <DialogContent className="flex flex-col gap-4 px-24 bg-white">
          <FormProvider {...methods}>
            <AddActionForm />
          </FormProvider>
        </DialogContent>

        <DialogActions className="flex justify-between items-center p-24 bg-white">
          <Button
            disableElevation
            className="text-11 bg-paper text-secondaryLight font-400"
            onClick={onClose}
            disabled={isAddingAction}
            variant="outlined"
            sx={{
              borderColor: 'secondary.light',
              ':hover': { borderColor: 'secondary.light' },
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disableElevation
            className="text-white text-11 font-400"
            variant="contained"
            color="primary"
            disabled={isAddingAction}
          >
            Create Action
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddActionModal;

import queryClient from 'app/configs/query-client';
import { TEmployeeList, THistory, TResponsePagination } from 'models';
import api from 'utils/api';

import { useMutation } from '@tanstack/react-query';

import { useGetList, useGetOne, usePutUpdate } from '../request';

const historyURL = '/api/manager/histories';

export const historyKeys = {
  all: ['history'] as const,
  lists: () => [...historyKeys.all, 'list'] as const,
  list: (filters: any) => [...historyKeys.lists(), { filters }] as const,
  details: () => [...historyKeys.all, 'detail'] as const,
  detail: (id: number) => [...historyKeys.details(), id] as const,
};

export const refetchTask = () => queryClient.invalidateQueries(historyKeys.all);

export function useGetHistories(filters: any, options?: { enabled?: boolean }) {
  return useGetList<TResponsePagination<THistory>>([...historyKeys.list(filters)], `${historyURL}`, filters, {
    refetchOnMount: true,
    ...options,
  });
}

export function useGetHistory(historyId: number) {
  return useGetOne<TEmployeeList>(
    [...historyKeys.detail(historyId)],
    `${historyURL}/${historyId}`,
    {},
    {
      enabled: !!historyId,
    },
  );
}

async function updateHistory({ taskId, responseId, payload }: { taskId: number; responseId: number; payload: any }) {
  const res = await api.patch(`${historyURL}/${taskId}/responses/${responseId}`, payload);
  return res.data;
}

export function useEditHistoryTask({ taskId, responseId }: { taskId: number; responseId: number }) {
  return useMutation(
    payload =>
      updateHistory({
        taskId,
        responseId,
        payload,
      }),
    {
      onSuccess() {
        refetchTask();
      },
    },
  );
}

export function useManagerFlagTask(taskId: number, responseId: number) {
  return usePutUpdate<any, any>(`${historyURL}/${taskId}/responses/${responseId}/flag`, {});
}

import ConfirmDialog from 'app/components/cores/confirm-dialog';
import FlagIcon from 'app/components/cores/icons/flag';
import { useEmployeeFlagTask } from 'app/hooks/api/employee/tasks';
import useTaskUrl from 'app/hooks/use-task-url';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';

import { IconButton } from '@mui/material';

type Props = {
  active: boolean;
  responseId: number;
  disabled: boolean;
};

const FlagButton = ({ active, responseId, disabled }: Props) => {
  const dispatch = useDispatch();
  const { listId } = useTaskUrl();
  const { mutateAsync: flagTask, isLoading: isFlaggingTask } = useEmployeeFlagTask(Number(listId), responseId);

  const handleCloseDialog = () => {
    dispatch(closeDialog({}));
  };

  const handleConfirm = () => {
    flagTask({});
    handleCloseDialog();
  };

  return (
    <IconButton
      size="small"
      className="text-15"
      disabled={disabled || isFlaggingTask}
      onClick={() => {
        dispatch(
          openDialog({
            children: (
              <ConfirmDialog
                title={active ? 'Unflag Task' : 'Flag Task'}
                message={
                  active ? 'Are you sure you want to unflag this task?' : 'Are you sure you want to flag this task?'
                }
                onClose={handleCloseDialog}
                onConfirm={handleConfirm}
                cancelButtonLabel="No, Cancel"
                confirmButtonLabel={active ? 'Yes, Unflag it' : 'Yes, Flag it'}
                isLoading={isFlaggingTask}
              />
            ),
          }),
        );
      }}
    >
      <FlagIcon className={clsx('text-18', active ? 'text-warning' : 'text-secondaryLight')} />
    </IconButton>
  );
};
export default FlagButton;

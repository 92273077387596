import { TTodoList } from 'models/employee/todo.model';
import { clsxm } from 'utils/clsxm';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import { Checkbox, FormControl, FormLabel, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import TodoTitle from './todo-title';
import ToggleList from './toggle-list';

type Value = boolean | null;

type Props = {
  disabled?: boolean;
  value?: Value;
  item?: any; // TODO update type
  todoList?: TTodoList;
  displayable?: boolean;
  onChange?: (value: Value) => void;
};

const CheckMarkButton = ({ disabled, value, item, todoList, displayable, onChange }: Props) => {
  const classes = useStyles();
  const { prompt } = item || {};
  const { orgRoles, users: assignees, important } = todoList || {};
  const isDisabled = !displayable || disabled;

  const handleComplete = (__: unknown, checked: boolean) => {
    if (displayable) {
      onChange?.(checked);
    }
  };

  const handleTitleClick = () => {
    const checked = !value;
    handleComplete(undefined, checked);
  };

  return (
    <div className="flex items-start justify-between">
      <FormControl className="w-full mt-4">
        <div className="flex items-start">
          <Checkbox
            classes={{ root: '-ml-12 py-0 mt-4' }}
            disabled={isDisabled}
            className={clsxm(isDisabled && classes.notAllow)}
            icon={<RadioButtonUncheckedIcon color={!isDisabled ? 'primary' : 'disabled'} />}
            checkedIcon={<CheckCircleIcon color={!isDisabled ? 'primary' : 'disabled'} />}
            checked={!!value}
            onChange={handleComplete}
          />

          <FormLabel
            className="flex-1 pr-16"
            onClick={handleTitleClick}
          >
            <div className="pl-2">
              <TodoTitle
                title={prompt}
                className={clsxm('text-13 font-600', !!value && 'line-through font-400')}
              />

              {/* Roles */}
              <ToggleList
                data={orgRoles || []}
                keyExtractor={role => `${role.externalId}`}
                limit={3}
                renderItem={role => (
                  <Typography
                    color="primary"
                    className="inline mr-4 text-13 font-600"
                    component="span"
                  >
                    #{role.name}
                  </Typography>
                )}
                icon={
                  <HowToRegOutlinedIcon
                    fontSize="small"
                    className="mb-4"
                  />
                }
                sign="#"
                getItemName={role => role.name}
              />

              {/* Assignees */}
              <ToggleList
                data={assignees || []}
                keyExtractor={assignee => `${assignee.id}`}
                limit={3}
                renderItem={assignee => (
                  <Typography
                    color="primary"
                    className="inline mr-4 text-13 font-600"
                    component="span"
                  >
                    @{assignee.fullName}
                  </Typography>
                )}
                icon={
                  <PeopleOutlinedIcon
                    fontSize="small"
                    className="mb-4"
                  />
                }
                sign="@"
                getItemName={assignee => assignee.fullName}
              />
            </div>
          </FormLabel>

          <div className="flex items-center gap-8">
            {important && <StarRoundedIcon className="text-warning text-20" />}
          </div>
        </div>
      </FormControl>
    </div>
  );
};

export default CheckMarkButton;

const useStyles = makeStyles({
  notAllow: {
    cursor: 'not-allowed !important',
  },
  disabled: {
    color: 'inherit !important',
  },
});

import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

type Props = {
  [key: string]: any;
};

function Flag(props: Props) {
  return (
    <SvgIcon
      {...props}
      style={{ fill: 'none' }}
      viewBox="0 0 18 16"
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 6C3 5.20435 3.31607 4.44129 3.87868 3.87868C4.44129 3.31607 5.20435 3 6 3H16C16.1857 3 16.3678 3.05171 16.5257 3.14935C16.6837 3.24698 16.8114 3.38668 16.8944 3.55279C16.9775 3.71889 17.0126 3.90484 16.996 4.08981C16.9793 4.27477 16.9114 4.45143 16.8 4.6L14.25 8L16.8 11.4C16.9114 11.5486 16.9793 11.7252 16.996 11.9102C17.0126 12.0952 16.9775 12.2811 16.8944 12.4472C16.8114 12.6133 16.6837 12.753 16.5257 12.8507C16.3678 12.9483 16.1857 13 16 13H6C5.73478 13 5.48043 13.1054 5.29289 13.2929C5.10536 13.4804 5 13.7348 5 14V17C5 17.2652 4.89464 17.5196 4.70711 17.7071C4.51957 17.8946 4.26522 18 4 18C3.73478 18 3.48043 17.8946 3.29289 17.7071C3.10536 17.5196 3 17.2652 3 17V6Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
}

export default React.memo(Flag);

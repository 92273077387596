import OrgRolesAssignment from 'app/components/cores/audit/org-roles-assignment';
import UserAssignment from 'app/components/cores/audit/user-assignment';
import ControlledDatePicker from 'app/components/cores/date-picker/controlled-date-picker';
import { ControlledTextInput } from 'app/components/cores/inputs/text';
import Toggle from 'app/components/cores/inputs/toggle';
import ControlledTimePicker from 'app/components/cores/time-picker/controlled-time-picker';
import AttributeContainer from 'app/components/lists/attribute-container';
import useTaskFilter from 'app/hooks/use-task-filter';
import moment from 'moment';
import { useFormContext } from 'react-hook-form';

import Typography from '@mui/material/Typography';

const AddActionForm = () => {
  const { location } = useTaskFilter();
  const { control } = useFormContext();
  const locationIds: number[] = location?.externalId ? [location.externalId] : [];

  return (
    <div className="space-y-24">
      <AttributeContainer label="Title *">
        <ControlledTextInput
          control={control}
          name="description"
          className="h-full shadow"
          variant="filled"
          size="small"
          placeholder="Action Title"
          InputProps={{
            className: 'bg-paper hover:bg-gray-200 border-right-width-1 p-4 rounded-8',
            disableUnderline: true,
            classes: {
              input: 'p-8 text-13',
            },
          }}
        />
      </AttributeContainer>

      <div className="flex gap-16 justify-between items-start">
        <div className="flex-1">
          <AttributeContainer label="Role">
            <OrgRolesAssignment
              name="orgRoles"
              control={control}
            />
          </AttributeContainer>
        </div>
        <div className="flex-1">
          <AttributeContainer label="Employee">
            <UserAssignment
              name="users"
              control={control}
              locationIds={locationIds}
            />
          </AttributeContainer>
        </div>
      </div>

      <div className="flex gap-16 justify-between items-start">
        <div className="flex-1">
          <AttributeContainer label="Start Date">
            <ControlledDatePicker
              control={control}
              name="startDate"
              className="p-4 w-full border-right-width-1 rounded-8"
              minDate={moment().startOf('day')}
            />
          </AttributeContainer>
        </div>
        <div className="flex-1">
          <AttributeContainer label="Start Time">
            <ControlledTimePicker
              control={control}
              name="startTime"
              className="p-4 w-full border-right-width-1 rounded-8"
            />
          </AttributeContainer>
        </div>
      </div>

      <div className="flex gap-16 justify-between items-start">
        <div className="flex-1">
          <AttributeContainer label="End Date">
            <ControlledDatePicker
              control={control}
              name="endDate"
              noDefault
              className="p-4 w-full border-right-width-1 rounded-8"
              minDate={moment().startOf('day')}
            />
          </AttributeContainer>
        </div>
        <div className="flex-1">
          <AttributeContainer label="End Time">
            <ControlledTimePicker
              control={control}
              name="endTime"
              className="p-4 w-full border-right-width-1 rounded-8"
            />
          </AttributeContainer>
        </div>
      </div>

      <div className="flex gap-8 items-center">
        <Toggle
          control={control}
          name="important"
        />
        <Typography className="font-600 text-13 text-secondaryMain">Mark as important</Typography>
      </div>
    </div>
  );
};

export default AddActionForm;

import ErrorMessage from 'app/components/cores/form/error-message';
import moment, { Moment } from 'moment';
import { Control, useController } from 'react-hook-form';

import { TimePickerProps } from '@mui/x-date-pickers/TimePicker';

import TimePicker from './time-picker';

type Props = {
  name: string;
  control?: Control;
  rules?: any;
  timeFormat?: string;
} & TimePickerProps<Moment>;

function ControlledTimePicker({ name, control, rules, timeFormat, ...datePickerProps }: Props) {
  const {
    field: { value, onChange, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  const format = timeFormat ?? 'h:mm A';

  const hasError = !!error?.message;

  const handleChange = (newValue: Moment | null) => {
    const newTime = newValue?.format(format);
    onChange(newTime);
  };

  return (
    <div>
      <TimePicker
        {...datePickerProps}
        ref={ref}
        value={moment(value, format) || null}
        onChange={handleChange}
      />
      {hasError && <ErrorMessage message={error?.message} />}
    </div>
  );
}

export default ControlledTimePicker;

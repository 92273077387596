import ErrorMessage from 'app/components/cores/form/error-message';
import SelectInfiniteScroll from 'app/components/cores/select-infinite-scroll';
import { useAdminGetEmployees } from 'app/hooks/api/employees';
import useSelect from 'app/hooks/use-select';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Control, useController } from 'react-hook-form';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  control: {
    minHeight: '48px !important',
  },
  text: {
    fontSize: '1.3rem !important',
    color: theme.palette.secondary.dark,
  },
}));

type Props = {
  name: string;
  control?: Control<any, any>;
  menuPlacement?: string;
  locationIds?: number[];
};

function UserAssignment({ name, control, menuPlacement, locationIds }: Props) {
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize] = useState(50);
  const isMounted = useRef(false);

  const userIdsName = `${name}.userIds`;
  const usersName = `${name}.users`;

  const { data, isFetching } = useAdminGetEmployees(
    {
      page: pageIndex,
      per_page: pageSize,
      'q[full_name_cont]': searchText,
      'q[location_id_eq]': locationIds || [],
    },
    {
      enabled: !!locationIds?.length,
    },
  );

  const {
    field: { onChange: onRoleIdsChange },
  } = useController({
    name: userIdsName,
    control,
  });

  const {
    field: { value, onChange, onBlur },
    fieldState: { error },
  } = useController({
    name: usersName,
    control,
  });

  const { options, handleSearch, handleLoadMore } = useSelect({
    data: data?.records,
    isLoading: isFetching,
    pageIndex,
    pageSize,
    currentPage: data?.page,
    setPageIndex,
    setSearchText,
  });

  const formattedOptions = useMemo(
    () => options?.map((option: any) => ({ name: option.name, id: option.id })),
    [options],
  );

  const presentValue = useMemo(() => {
    return !value?.length ? null : value;
  }, [value]);

  const handleChange = useCallback(
    (newValues: any) => {
      onChange(newValues);
      onRoleIdsChange(newValues?.map((v: any) => v.id));
    },
    [onChange, onRoleIdsChange],
  );

  // reset employees when roles change
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    handleChange([]);
  }, [locationIds, handleChange]);

  return (
    <div className="py-4">
      <SelectInfiniteScroll
        value={presentValue}
        onChange={handleChange}
        onBlur={onBlur}
        isMulti={true}
        isLoading={isFetching}
        isClearable={true}
        control={control}
        defaultOptions={true}
        className="w-full"
        name={name}
        placeholder="Employees"
        menuPlacement={menuPlacement || 'auto'}
        SelectProps={{
          classes: {
            control: classes.control,
            text: classes.text,
            placeholder: classes.text,
          },
        }}
        styles={{
          indicatorsContainer: (_props: any) => ({
            ..._props,
            marginTop: 7,
          }),
        }}
        options={formattedOptions || []}
        onInputChange={handleSearch}
        getOptionValue={(option: any) => option.id}
        getOptionLabel={(option: any) => option.name || option.fullName}
        onMenuScrollToBottom={handleLoadMore}
      />
      <ErrorMessage message={error?.message} />
    </div>
  );
}

export default UserAssignment;

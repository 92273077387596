import { formatDate } from 'utils/date';

import { Typography } from '@mui/material';

type Props = {
  name: string;
  time: string;
  mode?: 'added' | 'completed';
};

const AuthorTimeInfo = ({ name, time, mode = 'added' }: Props) => {
  return (
    <div className="flex items-center">
      <div className="flex items-center">
        <Typography
          className="text-11 font-500 text-secondaryLight"
          component="span"
        >
          {mode === 'added' ? 'Added by' : 'Completed by'}
        </Typography>
        &nbsp;
        <Typography
          className="text-11 font-600 text-secondaryLight"
          component="span"
        >
          {name}
        </Typography>
      </div>

      <div className="w-2 h-2 mx-8 rounded-full bg-secondaryMain" />

      <Typography className="text-11 font-500 text-secondaryLight">
        {formatDate(time, 'HH:mm')} - {formatDate(time, 'DD/MM/YYYY')}
      </Typography>
    </div>
  );
};
export default AuthorTimeInfo;
